<script lang="ts" setup>
import type { UseQueryReturnType } from '@tanstack/vue-query'
import isEmpty from 'lodash.isempty'

const props = defineProps<{ query: UseQueryReturnType<{ data?: object[] }, Error> }>()
const { isFetching, isFetched, isError, data } = props.query
// is here to prevent the initial loading indicator too early
const isDelayElapsed = useTimeout(200)
const isFetchingDelayed = refDelayed(isFetching, false)
const isDataEmpty = computed(() => data.value && isEmpty(data.value.data))
</script>

<template>
  <div
    v-if="isDelayElapsed || isFetched"
    class="overflow-hidden rounded-lg border"
  >
    <div
      v-if="isFetching && !data"
      class="flex justify-center p-4"
    >
      <DTLoaderDots class="my-1.5" />
    </div>
    <div
      v-if="data"
      class="relative z-0"
    >
      <div
        v-show="isFetchingDelayed"
        class="absolute inset-0 z-10 flex items-center justify-center bg-white/80"
      >
        <DTLoaderDots />
      </div>
      <slot
        v-if="!isDataEmpty"
        name="default"
      />
      <slot
        v-else
        name="empty"
      />
    </div>
    <slot
      v-else-if="isError"
      name="error"
    />
  </div>
</template>
